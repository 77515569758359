import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@/design-system/atoms/Typography';
import { trackInteraction } from '@/utils/analytics';

import styles from '../Tabs.module.scss';

export const Tab = React.forwardRef((props, ref) => {
    const {
        id,
        className,
        title,
        index,
        tabsRef,
        numberOfTabs,
        emblaApi,
        setSelectedTabIndex,
        ...otherProps
    } = props;

    const typographyRef = React.useRef();

    React.useLayoutEffect(() => {
        const updateWidth = () => {
            const { width } = typographyRef.current.getBoundingClientRect();
            typographyRef.current.style.width = `${Math.ceil(width)}px`;
        };

        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    const handleKeyDown = React.useCallback(
        (e) => {
            let newIndex = index;
            let flag = false;
            switch (e.key) {
                case 'ArrowLeft': {
                    newIndex = newIndex - 1;
                    if (newIndex < 0) {
                        newIndex = 0;
                    }

                    flag = true;
                    break;
                }

                case 'ArrowRight': {
                    newIndex = newIndex + 1;
                    if (newIndex > numberOfTabs - 1) {
                        newIndex = numberOfTabs - 1;
                    }

                    flag = true;
                    break;
                }

                case 'Home': {
                    newIndex = 0;
                    flag = true;
                    break;
                }

                case 'End': {
                    newIndex = numberOfTabs - 1;
                    flag = true;
                    break;
                }

                default:
                    break;
            }

            if (flag) {
                tabsRef.current[newIndex]?.focus();
                emblaApi.scrollTo(newIndex);

                e.stopPropagation();
                e.preventDefault();
            }
        },
        [emblaApi, numberOfTabs, index, tabsRef],
    );

    const handleClick = React.useCallback(
        (e, title) => {
            e.preventDefault();

            tabsRef.current[index]?.focus();
            emblaApi.scrollTo(index);
            setSelectedTabIndex(index);
            //analytics
            trackInteraction({
                componentName: 'Tab',
                actionLabel: title,
                interactionType: 'tab',
                selector: 'tab',
            });
        },
        [emblaApi, index, setSelectedTabIndex, tabsRef],
    );

    return (
        <button
            ref={ref}
            type="button"
            id={id}
            className={classnames(styles['tabs__item'])}
            onKeyDown={handleKeyDown}
            onClick={(event) => {
                handleClick(event, title);
            }}
            data-trigger="tab"
            {...otherProps}
        >
            <div ref={typographyRef} className={styles['tabs__typography-wrapper']}>
                <Typography
                    variant="t1"
                    content={title}
                    className={classnames(styles['tabs__typography'])}
                />
            </div>
        </button>
    );
});

Tab.propTypes = {
    /**
     * Unique id of the tab
     */
    id: PropTypes.string,
    /**
     * Title of the tab
     */
    title: PropTypes.string,
    /**
     * Tab index/position in the list to tabs
     */
    index: PropTypes.number,
    /**
     * Array of tab reference
     */
    tabsRef: PropTypes.object,
    /**
     * `emblaApi` object ([docs](https://www.embla-carousel.com/api/options/#reference))
     */
    emblaApi: PropTypes.object,
    /**
     * Number of tabs
     */
    numberOfTabs: PropTypes.number,
    /**
     * Function to set the selected tab index
     */
    setSelectedTabIndex: PropTypes.func,
};
