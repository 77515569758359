import window from '@/utils/window';

/**
 * Parses the query parameters from the hash of the current URL.
 * @returns {Object} An object containing the query parameters as key-value pairs.
 */
export default function parseHashQueryParameters() {
    var hash = window.location.hash.substr(1);
    var queryParams = {};
    if (hash) {
        var pairs = hash.split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);
            queryParams[key] = value;
        }
    }
    return queryParams;
}
