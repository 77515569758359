import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { useEmblaCarousel } from '@/hooks/useEmblaCarousel';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';
import parseHashQueryParameters from '@/utils/parseHashQueryParameters';
import window from '@/utils/window';

import { Divider } from './components/Divider';
import { SelectedTabBottomBorder } from './components/SelectedTabBottomBorder';
import { Tab } from './components/Tab';
import styles from './Tabs.module.scss';

/**
 * Scroll to the element if it's not in the viewport
 * @param {Element} el - The element to scroll to
 * @param {Number} percentageFromViewportTop - The percentage of the elements top from the viewport top to scroll to
 * @param {Number} delay - The delay in milliseconds before scrolling
 */
const scrollIfNeeded = (el, percentageFromViewportTop = 0.2, delay = 300) => {
    const { innerHeight } = window;
    const { top: elTop } = el.getBoundingClientRect();
    const threshold = innerHeight * percentageFromViewportTop;
    // If the element is beneath the viewport threshold or not in the viewport, scroll to it
    if (elTop > threshold || elTop < 0) {
        setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'start' }), delay);
    }
};

/**
 * Parse the url to determine the initial tab index based on the hash query parameter `tab`
 * @param {Number} activeIndex The default active tab index
 * @param {Array} tabs The tabs array frmo Tabs component
 * @returns {Object} The initial index and if the url has a tab
 *
 */
const parseUrl = (defaultActiveIndex, tabs) => {
    const urlParams = parseHashQueryParameters();
    let initialIndex = defaultActiveIndex;
    let hasUrlTab = false;
    if (urlParams.tab) {
        const urlTab = tabs.findIndex((tab) => tab.id === urlParams.tab);
        hasUrlTab = !!urlTab;
        // get hash params from url
        initialIndex = hasUrlTab ? urlTab : defaultActiveIndex;
    }

    return { hasUrlTab, initialIndex };
};

export const Tabs = (props) => {
    const { className, activeIndex = 0, ariaLabel, tabs, placeholderComponent } = props;

    const tabsRef = React.useRef([]);
    const initialPositionRef = React.useRef();
    const [hasSetInitialTab, setHasSetInitialTab] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(
        parseUrl(activeIndex, tabs).initialIndex,
    );
    /**
     * Set the initial tab index based on the url hash query parameter
     * and scroll to the tab if it is defined in the url
     */
    useEffect(() => {
        if (!hasSetInitialTab) {
            const { hasUrlTab, initialIndex } = parseUrl(selectedTabIndex, tabs);

            setSelectedTabIndex(initialIndex);
            if (hasUrlTab && tabsRef.current[initialIndex]) {
                scrollIfNeeded(tabsRef.current[initialIndex], 0.2, 0);
            }

            setHasSetInitialTab(true);
        }
    }, [hasSetInitialTab, tabs, selectedTabIndex, setSelectedTabIndex, setHasSetInitialTab]);

    // Set the selected tab index whenever on the activeIndex prop changes
    // !! Note: this is important for when tabs are toogle in authoring mode !!
    useEffect(() => {
        if (props.isInEditor) {
            setSelectedTabIndex(activeIndex);
        }
    }, [setSelectedTabIndex, activeIndex, props.isInEditor]);

    /**
     * Callback to set the selected tab index and update the url hash
     */
    const onSelectedTabIndex = (selectedIndex) => {
        const tabId = tabs.find((t, i) => i === selectedIndex)?.id;
        if (window && tabId) {
            window.location.hash = `tab=${tabId}`;
        }

        setSelectedTabIndex(selectedIndex);
    };

    const [emblaRef, emblaApi] = useEmblaCarousel({
        duration: 20,
        startIndex: selectedTabIndex,
    });

    if (!arrayNotEmpty(tabs)) {
        return null;
    }

    return (
        <div className={styles['root']}>
            <div className={classnames('embla', styles['tabs'], className)}>
                {placeholderComponent && (
                    <ContentContainer>{placeholderComponent}</ContentContainer>
                )}

                <ContentContainer
                    ref={emblaRef}
                    className={classnames('embla__viewport', styles['tabs__viewport'])}
                >
                    <div
                        role="tablist"
                        aria-label={ariaLabel}
                        className={classnames('embla__container', styles['tabs__container'])}
                    >
                        {tabs.map((t, i) => {
                            return (
                                <Tab
                                    key={i}
                                    ref={(node) => {
                                        tabsRef.current[i] = node;
                                    }}
                                    id={t.id}
                                    role="tab"
                                    aria-controls={`tab-panel--${t.id}`}
                                    aria-selected={selectedTabIndex === i ? 'true' : 'false'}
                                    tabIndex={selectedTabIndex === i ? 0 : -1}
                                    className={'embla__slide'}
                                    data-tab-index={i}
                                    title={t.title}
                                    index={i}
                                    tabsRef={tabsRef}
                                    numberOfTabs={tabs.length}
                                    emblaApi={emblaApi}
                                    setSelectedTabIndex={onSelectedTabIndex}
                                />
                            );
                        })}
                    </div>
                </ContentContainer>

                <ContentContainer>
                    <div ref={initialPositionRef} aria-hidden />
                    <SelectedTabBottomBorder
                        selectedTabIndex={selectedTabIndex}
                        tabsRef={tabsRef}
                        emblaApi={emblaApi}
                        initialPositionRef={initialPositionRef}
                    />
                    <Divider
                        tabsRef={tabsRef}
                        numberOfTabs={tabs.length}
                        initialPositionRef={initialPositionRef}
                    />
                </ContentContainer>
            </div>

            <ContentContainer className={styles['tab-panel__container']} isFullWidth={true}>
                {hasSetInitialTab &&
                    tabs.map((t, i) => (
                        <div
                            key={i}
                            id={`tab-panel--${t.id}`}
                            role="tabpanel"
                            aria-labelledby={t.id}
                            aria-hidden={i !== selectedTabIndex}
                            tabIndex={0}
                            className={classnames(
                                styles['tab-panel'],
                                selectedTabIndex !== i && styles['tab-panel--is-hidden'],
                            )}
                        >
                            {t.content}
                        </div>
                    ))}
            </ContentContainer>
        </div>
    );
};

Tabs.propTypes = {
    activeIndex: PropTypes.number,
    ariaLabel: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            titleAriaLabel: PropTypes.string,
            content: PropTypes.node,
        }),
    ),
};
