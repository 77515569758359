import {
    AllowedComponentPlaceholderList,
    AllowedComponentsContainer,
    Container,
} from '@adobe/aem-react-editable-components';
import classNames from 'classnames';

import { Tabs } from '@/design-system/atoms/Tabs';

/**
 * Customization of AllowedComponentsContainer
 *
 * @see https://github.com/adobe/aem-react-editable-components/blob/v1.1.6/src/components/allowedcomponents/AllowedComponentsContainer.tsx
 */
export class IntegrationTabs extends Container {
    static defaultProps = {
        _allowedComponentPlaceholderListEmptyLabel: 'No allowed components',
        cqItems: {},
        cqItemsOrder: [],
        cqPath: '',
    };

    /**
     * Add `aemNoDecoration` to child
     */
    connectComponentWithItem(ChildComponent, itemProps, itemKey) {
        const itemPath = this.getItemPath(itemKey);

        return (
            <ChildComponent
                {...itemProps}
                key={itemPath}
                cqPath={itemPath}
                aemNoDecoration={true}
                isInEditor={this.props.isInEditor}
                containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
            />
        );
    }

    render() {
        const {
            allowedComponents,
            _allowedComponentPlaceholderListEmptyLabel,
            title,
            activeIndexFromAuthorPanel,
            isInEditor,
        } = this.props;

        if (isInEditor && allowedComponents && allowedComponents.applicable) {
            const emptyLabel =
                _allowedComponentPlaceholderListEmptyLabel ||
                AllowedComponentsContainer.defaultProps._allowedComponentPlaceholderListEmptyLabel;

            if (_allowedComponentPlaceholderListEmptyLabel) {
                return (
                    <AllowedComponentPlaceholderList
                        title={title}
                        emptyLabel={emptyLabel}
                        components={allowedComponents.components}
                        placeholderProps={this.placeholderProps}
                        cqPath={this.props.cqPath}
                    />
                );
            }
        }
        const tabs = [];
        this.childComponents?.forEach((component) => {
            const title = component.props['cq:panelTitle'];
            const id = component.props['tabId'];
            const content = component;
            const hasItems =
                component.props.cqItemsOrder && component.props.cqItemsOrder.length > 0;
            const isInEditor = component.props.isInEditor;

            if (isInEditor || (hasItems && !isInEditor)) {
                tabs.push({
                    title,
                    id,
                    content,
                });
            }
        });

        const isInEditorProps = isInEditor
            ? {
                  'data-panelcontainer': 'tabs',
                  className: classNames(this.containerProps.className, 'cmp-tabs'),
                  'data-cmp-is': 'tabs',
              }
            : {};

        return (
            <div {...this.containerProps} {...isInEditorProps}>
                <Tabs
                    key={tabs.map((t) => t.title).join(';') || undefined}
                    tabs={tabs}
                    activeIndex={activeIndexFromAuthorPanel}
                    placeholderComponent={this.placeholderComponent}
                    isInEditor={this.props.isInEditor}
                />
            </div>
        );
    }
}

export default IntegrationTabs;
