import PropTypes from 'prop-types';
import React from 'react';

import window from '@/utils/window';

import styles from '../Tabs.module.scss';

export const SelectedTabBottomBorder = (props) => {
    const { selectedTabIndex, tabsRef, emblaApi, initialPositionRef } = props;

    const ref = React.useRef();
    const transitionDuration = {
        js: 350,
        css: '0.35s',
    };

    const currentSelectedTabIndexRef = React.useRef(selectedTabIndex);

    React.useLayoutEffect(() => {
        if (currentSelectedTabIndexRef.current === selectedTabIndex) {
            ref.current.style['transition'] = 'none';
            return;
        }

        let updateValuesTimeoutId = null;
        const updateValues = () => {
            updateValuesTimeoutId = setTimeout(() => {
                ref.current.style['transition'] = 'none';
                currentSelectedTabIndexRef.current = selectedTabIndex;
            }, transitionDuration.js);
        };

        ref.current.style['transition'] = '';
        const handleAnimation = async () => {
            const isScrolling = await new Promise((resolve) => {
                let timeoutId = null;
                const handleScroll = () => {
                    resolve(true);
                    clearTimeout(timeoutId);
                    emblaApi.off('scroll', handleScroll);
                };
                emblaApi.on('scroll', handleScroll);
                timeoutId = setTimeout(() => resolve(false), Math.ceil(1000 / 60) + 1); // FPS + 1
            });

            if (!isScrolling) {
                updateValues();
                return;
            }

            const handleSettle = () => {
                updateValues();
                emblaApi.off('settle', handleSettle);
            };
            emblaApi.on('settle', handleSettle);
        };

        handleAnimation();

        return () => {
            updateValuesTimeoutId && clearTimeout(updateValuesTimeoutId);
        };
    }, [emblaApi, selectedTabIndex, transitionDuration.js]);

    React.useLayoutEffect(() => {
        let requestAnimationFrameId = null;
        const requestAnimationFrameStep = () => {
            const selectedTab = tabsRef.current[selectedTabIndex];

            if (!selectedTab) {
                requestAnimationFrameId = window.requestAnimationFrame(requestAnimationFrameStep);
                return;
            }

            const { left, width } = selectedTab.getBoundingClientRect();
            const { left: initialLeft } = initialPositionRef.current.getBoundingClientRect();

            const x = `${left - initialLeft}px`;

            ref.current.style.width = `${width}px`;
            ref.current.style.transform = `translateX(${x})`;

            requestAnimationFrameId = window.requestAnimationFrame(requestAnimationFrameStep);
        };

        requestAnimationFrameStep();
        return () => {
            requestAnimationFrameId && window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, [selectedTabIndex, tabsRef, initialPositionRef]);

    return (
        <div
            ref={ref}
            className={styles['selected-tab-bottom-border']}
            style={{ '--transition-duration': transitionDuration.css }}
        />
    );
};

SelectedTabBottomBorder.propTypes = {
    /**
     * Index of the selected tab
     */
    selectedTabIndex: PropTypes.number,
    /**
     * Array of tab reference
     */
    tabsRef: PropTypes.object,
    /**
     * `emblaApi` object ([docs](https://www.embla-carousel.com/api/options/#reference))
     */
    emblaApi: PropTypes.object,
    /**
     * Element reference to the positional element, used to calculate the
     * position of the selector
     */
    initialPositionRef: PropTypes.object,
};
