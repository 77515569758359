import PropTypes from 'prop-types';
import React from 'react';

import window from '@/utils/window';

import styles from '../Tabs.module.scss';

export const Divider = (props) => {
    const { tabsRef, numberOfTabs, initialPositionRef } = props;

    const ref = React.useRef();

    React.useLayoutEffect(() => {
        let requestAnimationFrameId = null;
        const requestAnimationFrameStep = () => {
            const firstTab = tabsRef.current[0];
            const lastTab = tabsRef.current[numberOfTabs - 1];

            if (!firstTab || !lastTab) {
                requestAnimationFrameId = window.requestAnimationFrame(requestAnimationFrameStep);
                return;
            }

            const { left } = firstTab.getBoundingClientRect();
            const { right } = lastTab.getBoundingClientRect();

            const width = right - left;
            const { left: initialLeft, width: minWidth } =
                initialPositionRef.current.getBoundingClientRect();

            const x = `${left - initialLeft}px`;

            ref.current.style.width = `${Math.max(minWidth, width)}px`;
            ref.current.style.transform = `translateX(${x})`;

            requestAnimationFrameId = window.requestAnimationFrame(requestAnimationFrameStep);
        };

        requestAnimationFrameStep();
        return () => {
            requestAnimationFrameId && window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, [tabsRef, numberOfTabs, initialPositionRef]);

    return <div ref={ref} className={styles['divider']} style={{ width: 0 }} />;
};

Divider.propTypes = {
    /**
     * Array of tab reference
     */
    tabsRef: PropTypes.object,
    /**
     * Number of tabs
     */
    numberOfTabs: PropTypes.number,
    /**
     * Element reference to the positional element, used to calculate the
     * position of the selector
     */
    initialPositionRef: PropTypes.object,
};
